import React, {useEffect, useState} from 'react';
import './DynamicAlerts.scss';
import {autoEmbed, autolinker, isPresent, markdownToHtml} from "../../core/utils";
import { Autolinker } from "../../core/components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as api from "./api";

const DynamicAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [activeAlert, setActiveAlert] = useState();

  const dismissAlert = () => {
    api.dismissAlert(activeAlert.id);
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (user.alerts) {
      user.alerts = user.alerts.filter((alert) => alert.id !== activeAlert.id);
      sessionStorage.setItem('user', JSON.stringify(user));
      setAlerts(() => user.alerts);
      setActiveAlert(() => user.alerts[0]);
    }
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (isPresent(user) && isPresent(user.alerts)) {
      setAlerts(() => user.alerts);
      setActiveAlert(() => user.alerts[0]);
    }
  }, []);

  const content =  activeAlert?.content ? markdownToHtml(autolinker(activeAlert.content)) : '';

  return (
      <>
        {
          isPresent(activeAlert) &&
            <div className="DynamicAlerts">
                <div className="col-md-6 col-xl-4">
                  <div className="card rounded border">
                    <div className="card-body d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <div dangerouslySetInnerHTML={{__html: content}}/>
                      </div>
                      {
                        activeAlert.dismissible &&
                        <div className="cursor-pointer">
                          <FontAwesomeIcon icon={faTimes} onClick={dismissAlert} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
            </div>
        }
      </>
  )
};

export default DynamicAlerts;